/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import RootStateComponent from './src/components/RootStateComponent';
import 'bootstrap/dist/js/bootstrap.min.js'

export const wrapRootElement  = ({ element, props }) => (
    <RootStateComponent {...props}>{element}</RootStateComponent>
  );