import React, { useState, useEffect } from "react"
import { AppStateContext } from "../contexts/AppStateContext"


const RootStateComponent = ({children}) => {
    const isBrowser = typeof window !== `undefined`

    // Default values for state
    const defaultStateData = {
        profileUuid: "",
        loggedIn:false,
        institution:undefined,
        paymentPage:true,
        sessionId:"",
        plan:"basic",
        planUpgrade:false,
        account: "",
        redirect: "/dashboard"
    }

    // Try to get data from the local storage and create if not found
    let appStateFromStorage = null;

    if(isBrowser){
        appStateFromStorage =  window.localStorage.getItem("AppState");
    }

    if(!appStateFromStorage){
        const defaultStateAsString = JSON.stringify(defaultStateData);
        // if(isBrowser){
        //     window.localStorage.setItem("AppState", defaultStateAsString);
        // }
        appStateFromStorage = defaultStateAsString;
    }

    appStateFromStorage = JSON.parse(appStateFromStorage);

     // Define State vars
    const [profileUuid, setProfileUuid] = useState(appStateFromStorage.profileUuid);
    const [loggedIn, setLoggedIn] = useState(appStateFromStorage.loggedIn);
    const [paymentPage, setPaymentPage] = useState(appStateFromStorage.paymentPage);
    const [institution, setInstitution] = useState(appStateFromStorage.institution);
    const [sessionId, setSessionId] = useState(appStateFromStorage.sessionId);
    const [plan, setPlan] = useState(appStateFromStorage.plan);
    const [planUpgrade, setPlanUpgrade] = useState(appStateFromStorage.planUpgrade);
    const [account, setAccount] = useState(appStateFromStorage.account);
    const [redirect, setRedirect] = useState(appStateFromStorage.redirect);

    useEffect(()=>{
        // Update local data if there are changes in the variables from global state
        if(isBrowser){
            window.localStorage.setItem("AppState", JSON.stringify({
                profileUuid: profileUuid,
                loggedIn:loggedIn,
                institution:institution,
                paymentPage:paymentPage,
                sessionId:sessionId,
                plan:plan,
                planUpgrade:planUpgrade,
                account:account,
                redirect:redirect
            }));
        }
    },[profileUuid, loggedIn, paymentPage, institution, sessionId, plan, planUpgrade, account, redirect])

    const mergedState = {
        profileUuid:{value:profileUuid,set:setProfileUuid},
        loggedIn:{value:loggedIn,set:setLoggedIn},
        institution:{value:institution,set:setInstitution},
        paymentPage:{value:paymentPage,set:setPaymentPage},
        sessionId:{value:sessionId,set:setSessionId},
        plan:{value:plan,set:setPlan},
        planUpgrade:{value:planUpgrade,set:setPlanUpgrade},
        account:{value:account,set:setAccount},
        redirect:{value:redirect,set:setRedirect}
    };

    return (
        <AppStateContext.Provider value={mergedState}>
            {children}
        </AppStateContext.Provider>
    )
}

export default RootStateComponent
